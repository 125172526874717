import { FC, lazy, Suspense, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from 'src/_metronic/assets/ts/_utils'
import { WithChildren } from 'src/_metronic/helpers'
import { MasterLayout } from 'src/_metronic/layout/MasterLayout'
import { useAuth } from 'src/app/modules/auth'
import { MainViewWrapper } from 'src/app/modules/crews/MainView'
import { getProjects } from 'src/app/modules/projects/core/_requests'

// import { MenuTestPage } from '../pages/MenuTestPage'
// import { CustomWrapper } from '../pages/custom'
// import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { ProjectsWrapper } from '../pages/projects/ProjectsWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/admin/UsersPage'))
  const TasksPage = lazy(() => import('../modules/admin/TasksPage'))
  const ProjectDetailPage = lazy(() => import('../modules/projects/ProjectDetailPage'))

  const { currentUser } = useAuth()

  // NOTE: default navigation to #1 project crew in the list
  const { data: projectList } = useQuery('projects-list', getProjects, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
  const navigate = useNavigate()
  useEffect(() => {
    if (projectList?.length) {
      navigate(`/project_crews/${projectList[0].id}`)
    }
  }, [projectList])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='projects' element={<ProjectsWrapper />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* <Route path='custom' element={<CustomWrapper />} /> */}
        {/* Lazy Modules */}
        <Route
          path='projects/:id/*'
          element={
            <SuspensedView>
              <ProjectDetailPage />
            </SuspensedView>
          }
        />
        <Route path='project_crews/:id/*' element={<MainViewWrapper />} />
        {/* <Route
          path='project_crews/:id'
          element={
            <SuspensedView>
              <ProjectCrews />
            </SuspensedView>
          }
        />
        <Route
          path='project_crews/:id/:crewId'
          element={
            <SuspensedView>
              <CrewView />
            </SuspensedView>
          }
        /> */}
        {currentUser?.isAdmin && (
          <>
            <Route
              path='crafted/pages/profile/*'
              element={
                <SuspensedView>
                  {/* <ProjectDetailPage /> */}

                  <ProfilePage />
                </SuspensedView>
              }
            />
            <Route
              path='crafted/pages/wizards/*'
              element={
                <SuspensedView>
                  <WizardsPage />
                </SuspensedView>
              }
            />
            <Route
              path='crafted/widgets/*'
              element={
                <SuspensedView>
                  <WidgetsPage />
                </SuspensedView>
              }
            />
            <Route
              path='crafted/account/*'
              element={
                <SuspensedView>
                  <AccountPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/chat/*'
              element={
                <SuspensedView>
                  <ChatPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/user-management/*'
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />
            <Route
              path='/task-management/*'
              element={
                <SuspensedView>
                  <TasksPage />
                </SuspensedView>
              }
            />
            {/* Page Not Found */}
          </>
        )}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
