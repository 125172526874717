import { FC } from 'react'
import { Link } from 'react-router-dom'

import { IconUserModel } from 'src/app/modules/profile/ProfileModels'
import { UsersList } from 'src/app/modules/profile/components/UsersList'
import { BCID, ProjectStatus } from 'src/app/modules/projects/core/_models'

import { useProgressColor } from '../core/_hooks'
import { ProjectAvatar } from './ProjectAvatar'
import { StatusBadge } from './StatusBadge'

type Props = {
  id: BCID
  badgeColor: string
  status: ProjectStatus
  title: string
  description: string
  date: string
  budget: string
  progress: number
  avatar: string | null
  users?: Array<IconUserModel>
}

export const ProjectCard: FC<Props> = ({
  id,
  status,
  title,
  description,
  users = undefined,
  progress,
  avatar,
}) => {
  const progressColor = useProgressColor(progress)
  return (
    <Link to={id + ''} className='card border border-2 border-gray-300 border-hover'>
      <div className='d-flex p-8'>
        <div className='col-md-4'>
          {/* <img
            src={toAbsoluteUrl('/media/projects/4.png')}
            alt='card2'
            className='w-100 h-100 rounded'
          /> */}
          <ProjectAvatar avatar={avatar} />
        </div>
        <div className='col-md-8'>
          <div className='card-header border-0 d-flex align-items-start pe-0'>
            <div>
              <div className='fs-3 fw-bolder text-dark'>{title}</div>

              <div className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{description}</div>
            </div>

            <StatusBadge status={status} />
          </div>

          <div className='card-body p-9 pe-0 pb-0'>
            {/* <div className='d-flex flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>{date}</div>
                <div className='fw-bold text-gray-400'>Due Date</div>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <div className='fs-6 text-gray-800 fw-bolder'>{budget}</div>
                <div className='fw-bold text-gray-400'>Budget</div>
              </div>
            </div> */}
            <div className='d-flex gap-3 align-items-center'>
              <div
                className='h-4px w-100 bg-light'
                data-bs-toggle='tooltip'
                title='This project completed'
              >
                <div
                  className={`bg-${progressColor} rounded h-4px`}
                  role='progressbar'
                  style={{ width: `${(progress || 0) * 100}%` }}
                ></div>
              </div>
              <div className='text-gray-400'>{(progress * 100).toFixed(0)}%</div>
            </div>

            <UsersList users={users} />
          </div>
        </div>
      </div>
    </Link>
  )
}
