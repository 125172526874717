import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { PageTitle } from 'src/_metronic/layout/core'
import { ProjectList } from 'src/app/modules/projects/components/ProjectList'

export interface IconUserModel {
  name: string
  avatar?: string
  color?: string
  initials?: string
}

const ProjectsPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]} description='List of Projects'>
        Projects
      </PageTitle>
      <div className='g-5 gx-xxl-8'>
        <ProjectList />
      </div>
    </>
  )
}

const ProjectsWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.PROJECTS' })}</PageTitle>
      <ProjectsPage />
    </>
  )
}

export { ProjectsWrapper }
