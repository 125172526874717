import axios, { AxiosResponse, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

import { Response } from 'src/_metronic/helpers'
import { Report } from 'src/app/modules/admin/core/_models'
import { User } from 'src/app/modules/admin/core/_models'
import { REPORTS_URL } from 'src/app/modules/admin/core/_requests'

import {
  ActionItem,
  BCID,
  ComplianceRun,
  DocumentCreate,
  Project,
  ProjectCreate,
  UploadFileLinkResponse,
  Document,
  ProjectStatus,
  ProjectUserCreate,
  ProjectUpdate,
  Building,
  BuildingUpdate,
  DocumentImage,
  DocumentImageUpdate,
  ImageElement,
  ImageElementCreate,
  ImageElementUpdate,
  ImageFootprint,
  ImageFootprintCreate,
  ImageFootprintUpdate,
  ImageObject,
  ImageObjectCreate,
  ImageObjectUpdate,
  ImageScale,
  ImageScaleCreate,
  ImageScaleUpdate,
  ImageSegment,
  ImageSegmentCreate,
  ImageSegmentUpdate,
  ImageLabel,
  ImageLabelCreate,
  ImageLabelUpdate,
  ImageLine,
  ImageLineCreate,
  ImageLineUpdate,
  ImageSummary,
  ImageWall,
  ImageWallCreate,
  ImageWallUpdate,
  EstimationPrice,
  EstimationPriceUpsert,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const openaiApiKey = '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'

export const LOGIN_URL = `${API_URL}/v1/auth/login`
export const PROJECTS_URL = `${API_URL}/v1/projects`
export const USERS_URL = `${API_URL}/v1/users`
export const FILES_URL = `${API_URL}/v1/files`
export const DOCUMENTS_URL = `${API_URL}/v1/documents`
export const IMAGES_URL = `${API_URL}/v1/images`
export const SEGMENTS_URL = `${API_URL}/v1/segments`
export const WALLS_URL = `${API_URL}/v1/walls`
export const RUNS_URL = `${API_URL}/v1/runs`
export const OBJECTS_URL = `${API_URL}/v1/objects`
export const ELEMENTS_URL = `${API_URL}/v1/elements`
export const FOOTPRINTS_URL = `${API_URL}/v1/footprints`
export const LABELS_URL = `${API_URL}/v1/labels`
export const LINES_URL = `${API_URL}/v1/lines`
export const SCALES_URL = `${API_URL}/v1/scales`

export const createProject = async (project: ProjectCreate): Promise<Project | undefined> => {
  return axios.post(PROJECTS_URL, project).then((response) => response.data)
}

export const updateProject = async (
  id: BCID,
  project: ProjectUpdate,
): Promise<Project | undefined> => {
  return axios
    .put(`${PROJECTS_URL}/${id}`, project)
    .then((response: AxiosResponse<Response<Project>>) => response.data)
    .then((response: Response<Project>) => response.data)
}

export const getProjects = async (): Promise<Project[] | undefined> => {
  return axios.get(PROJECTS_URL).then((response) => response.data)
}

export const getProjectById = async (id: BCID): Promise<Project | undefined> => {
  return axios.get(`${PROJECTS_URL}/${id}`).then((response) => response.data)
}

export const archiveProjectById = async (id: BCID): Promise<Project | undefined> => {
  return axios
    .put(`${PROJECTS_URL}/${id}`, { status: ProjectStatus.ARCHIVED })
    .then((response) => response.data)
}

export const deleteProjectById = async (id: BCID): Promise<Project | undefined> => {
  return axios.delete(`${PROJECTS_URL}/${id}`).then((response) => response.data)
}

export const labelProjectById = async (
  id: BCID,
  includeAnnotations: boolean,
): Promise<Project | undefined> => {
  return axios
    .put(`${PROJECTS_URL}/${id}/labeling-job?include_annotations=${includeAnnotations}`, {})
    .then((response) => response.data)
}

export const addProjectUser = async (
  projectId: BCID,
  projectUser: ProjectUserCreate,
): Promise<Project | undefined> => {
  return axios
    .post(`${PROJECTS_URL}/${projectId}/users`, projectUser)
    .then((response: AxiosResponse<Response<Project>>) => response.data)
    .then((response: Response<Project>) => response.data)
}

export const updateProjectUser = async (
  projectId: BCID,
  projectUserId: BCID,
  projectUser: Partial<ProjectUserCreate>,
): Promise<Project | undefined> => {
  return axios
    .put(`${PROJECTS_URL}/${projectId}/users/${projectUserId}`, projectUser)
    .then((response) => response.data)
}

export const deleteProjectUser = async (
  projectId: BCID,
  projectUserId: BCID,
): Promise<Project | undefined> => {
  return axios
    .delete(`${PROJECTS_URL}/${projectId}/users/${projectUserId}`)
    .then((response) => response.data)
}

export const getUsers = async (): Promise<User[] | undefined> => {
  return axios.get(USERS_URL).then((response) => response.data)
}

export const getReports = async (document_id: BCID): Promise<Report[] | undefined> => {
  return axios.get(`${REPORTS_URL}?document_id=${document_id}`).then((response) => response.data)
}

export const getUploadLink = async (): Promise<UploadFileLinkResponse | undefined> => {
  return axios.get(`${FILES_URL}/upload`).then((response) => response.data)
}

export const getPublicUploadLink = async (): Promise<UploadFileLinkResponse> => {
  return axios.get(`${FILES_URL}/public_upload`).then((response) => response.data)
}

export const getDownloadLink = async (uuid: BCID): Promise<string | undefined> => {
  return axios.get(`${FILES_URL}/download/${uuid}`).then((response) => response.data)
}

export const labelFileById = async (
  uuid: BCID,
  includeAnnotations: boolean,
): Promise<string | undefined> => {
  return axios
    .post(`${DOCUMENTS_URL}/${uuid}/labeling-job?include_annotations=${includeAnnotations}`)
    .then((response) => response.data)
}

export const uploadFile = async (
  link: string,
  file: File,
  onProgress?: AxiosRequestConfig<File>['onUploadProgress'],
): Promise<AxiosResponse> => {
  const config = onProgress
    ? {
        onUploadProgress: onProgress,
      }
    : {}
  return axios.put(link, file, config)
}

export const downloadFile = async (fileUrl: string, headers: AxiosRequestHeaders = {}) => {
  return await axios.get(fileUrl, {
    headers,
    responseType: 'blob',
  })
}

export const createDocument = async (document: DocumentCreate): Promise<Document | undefined> => {
  return axios
    .post(DOCUMENTS_URL, document)
    .then((response: AxiosResponse<Response<Document>>) => response.data)
    .then((response: Response<Document>) => response.data)
}

export const getDocuments = async (projectId: BCID): Promise<Document[] | undefined> => {
  return axios.get(`${PROJECTS_URL}/${projectId}/documents`).then((response) => response.data)
}

export const getActionItems = async (projectId?: BCID): Promise<ActionItem[] | undefined> => {
  if (!projectId) {
    return
  }
  return axios.get(`${PROJECTS_URL}/${projectId}/action_items`).then((response) => response.data)
}

interface OpenAIConfig {
  model: string
  messages: {
    role: string
    content: string
  }[]
}

export const openAIAPI = async (data: OpenAIConfig) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${openaiApiKey}`,
    },
  }

  try {
    const response = await axios.post('https://api.openai.com/v1/chat/completions', data, config)
    return response.data.choices[0].message.content
  } catch (error) {
    console.error('Error in API call:', error)
    return ''
  }
}

export const getBuildingsList = async (projectId?: BCID): Promise<Building[] | undefined> => {
  if (!projectId) {
    return []
  }
  return axios.get(`${PROJECTS_URL}/${projectId}/buildings`).then((response) => response.data)
}

export const updateBuilding = async (
  projectId: BCID,
  buildingId: BCID,
  building: BuildingUpdate,
): Promise<Building | undefined> => {
  return axios
    .put(`${PROJECTS_URL}/${projectId}/buildings/${buildingId}`, building)
    .then((response) => response.data)
}

export const deleteBuilding = async (
  projectId: BCID,
  buildingId: BCID,
): Promise<Building | undefined> => {
  return axios
    .delete(`${PROJECTS_URL}/${projectId}/buildings/${buildingId}`)
    .then((response) => response.data)
}

export const createBuilding = async (
  projectId: BCID,
  building: BuildingUpdate,
): Promise<Building | undefined> => {
  return axios
    .post(`${PROJECTS_URL}/${projectId}/buildings`, building)
    .then((response) => response.data)
}

export const getEstimationPriceList = async (projectId?: BCID): Promise<EstimationPrice[]> => {
  if (!projectId) {
    return []
  }
  return axios
    .get(`${PROJECTS_URL}/${projectId}/estimation_prices`)
    .then((response) => response.data || [])
}

export const upsertEstimationPrice = async (
  projectId: BCID,
  estimationPriceItem: EstimationPriceUpsert,
): Promise<EstimationPrice | undefined> => {
  return axios
    .post(`${PROJECTS_URL}/${projectId}/estimation_prices`, estimationPriceItem)
    .then((response) => response.data)
}

export const getDocument = async (documentId: BCID): Promise<Document | undefined> => {
  return axios.get(`${DOCUMENTS_URL}/${documentId}`).then((response) => response.data)
}

export const deleteDocument = async (documentId: BCID): Promise<Document | undefined> => {
  return axios.delete(`${DOCUMENTS_URL}/${documentId}`).then((response) => response.data)
}

export const getImageList = async (documentId: BCID): Promise<DocumentImage[] | undefined> => {
  return axios.get(`${DOCUMENTS_URL}/${documentId}/images`).then((response) => response.data)
}

export const updateImage = async (
  imageId: BCID,
  image: DocumentImageUpdate,
): Promise<DocumentImage | undefined> => {
  return axios.put(`${IMAGES_URL}/${imageId}`, image).then((response) => response.data)
}

export const getImageSummary = async (imageId?: BCID): Promise<ImageSummary | undefined> => {
  if (!imageId) return {}
  return axios.get(`${IMAGES_URL}/${imageId}/summary`).then((response) => response.data || [])
}

export const getObjectsList = async (imageId?: BCID): Promise<ImageObject[]> => {
  if (!imageId) return []
  return axios.get(`${IMAGES_URL}/${imageId}/objects`).then((response) => response.data || [])
}

export const getSegmentList = async (imageId?: BCID): Promise<ImageSegment[]> => {
  if (!imageId) return []
  return axios.get(`${IMAGES_URL}/${imageId}/segments`).then((response) => response.data || [])
}

export const getWallList = async (imageId?: BCID): Promise<ImageWall[]> => {
  if (!imageId) return []
  return axios.get(`${IMAGES_URL}/${imageId}/walls`).then((response) => response.data || [])
}

export const getElementList = async (imageId?: BCID): Promise<ImageElement[]> => {
  if (!imageId) return []
  return axios.get(`${IMAGES_URL}/${imageId}/elements`).then((response) => response.data || [])
}

export const getScaleList = async (imageId?: BCID): Promise<ImageScale[]> => {
  if (!imageId) return []
  return axios.get(`${IMAGES_URL}/${imageId}/scales`).then((response) => response.data || [])
}

export const getLabelList = async (imageId?: BCID): Promise<ImageLabel[]> => {
  if (!imageId) return []
  return axios.get(`${IMAGES_URL}/${imageId}/labels`).then((response) => response.data || [])
}

export const getLineList = async (imageId?: BCID): Promise<ImageLine[]> => {
  if (!imageId) return []
  return axios.get(`${IMAGES_URL}/${imageId}/lines`).then((response) => response.data || [])
}

export const getFootprintList = async (imageId?: BCID): Promise<ImageFootprint[]> => {
  if (!imageId) return []
  return axios.get(`${IMAGES_URL}/${imageId}/footprints`).then((response) => response.data || [])
}

export const createFootprint = async (
  footprint: ImageFootprintCreate,
): Promise<ImageFootprint | undefined> => {
  return axios.post(FOOTPRINTS_URL, footprint).then((response) => response.data)
}

export const updateFootprint = async (
  footprintId: BCID,
  footprint: ImageFootprintUpdate,
): Promise<ImageFootprint | undefined> => {
  return axios.put(`${FOOTPRINTS_URL}/${footprintId}`, footprint).then((response) => response.data)
}

export const deleteFootprint = async (footprintId: BCID): Promise<ImageFootprint | undefined> => {
  return axios.delete(`${FOOTPRINTS_URL}/${footprintId}`).then((response) => response.data)
}

export const createElement = async (
  element: ImageElementCreate,
): Promise<ImageElement | undefined> => {
  return axios.post(ELEMENTS_URL, element).then((response) => response.data)
}

export const updateElement = async (
  elementId: BCID,
  element: ImageElementUpdate,
): Promise<ImageElement | undefined> => {
  return axios.put(`${ELEMENTS_URL}/${elementId}`, element).then((response) => response.data)
}

export const deleteElement = async (elementId: BCID): Promise<ImageElement | undefined> => {
  return axios.delete(`${ELEMENTS_URL}/${elementId}`).then((response) => response.data)
}

export const createScale = async (scale: ImageScaleCreate): Promise<ImageScale | undefined> => {
  return axios.post(SCALES_URL, scale).then((response) => response.data)
}

export const updateScale = async (
  scaleId: BCID,
  scale: ImageScaleUpdate,
): Promise<ImageScale | undefined> => {
  return axios.put(`${SCALES_URL}/${scaleId}`, scale).then((response) => response.data)
}

export const deleteScale = async (scaleId: BCID): Promise<ImageScale | undefined> => {
  return axios.delete(`${SCALES_URL}/${scaleId}`).then((response) => response.data)
}

export const createLabel = async (label: ImageLabelCreate): Promise<ImageLabel | undefined> => {
  return axios.post(LABELS_URL, label).then((response) => response.data)
}

export const updateLabel = async (
  labelId: BCID,
  label: ImageLabelUpdate,
): Promise<ImageLabel | undefined> => {
  return axios.put(`${LABELS_URL}/${labelId}`, label).then((response) => response.data)
}

export const deleteLabel = async (labelId: BCID): Promise<ImageLabel | undefined> => {
  return axios.delete(`${LABELS_URL}/${labelId}`).then((response) => response.data)
}

export const createLine = async (line: ImageLineCreate): Promise<ImageLine | undefined> => {
  return axios.post(LINES_URL, line).then((response) => response.data)
}

export const updateLine = async (
  lineId: BCID,
  line: ImageLineUpdate,
): Promise<ImageLine | undefined> => {
  return axios.put(`${LINES_URL}/${lineId}`, line).then((response) => response.data)
}

export const deleteLine = async (lineId: BCID): Promise<ImageLine | undefined> => {
  return axios.delete(`${LINES_URL}/${lineId}`).then((response) => response.data)
}

export const createSegment = async (
  segment: ImageSegmentCreate,
): Promise<ImageSegment | undefined> => {
  return axios.post(SEGMENTS_URL, segment).then((response) => response.data)
}

export const updateSegment = async (
  segmentId: BCID,
  segment: ImageSegmentUpdate,
): Promise<ImageSegment | undefined> => {
  return axios.put(`${SEGMENTS_URL}/${segmentId}`, segment).then((response) => response.data)
}

export const deleteSegment = async (segmentId: BCID): Promise<ImageSegment | undefined> => {
  return axios.delete(`${SEGMENTS_URL}/${segmentId}`).then((response) => response.data)
}

export const createWall = async (wall: ImageWallCreate): Promise<ImageWall | undefined> => {
  return axios.post(WALLS_URL, wall).then((response) => response.data)
}

export const updateWall = async (
  wallId: BCID,
  wall: ImageWallUpdate,
): Promise<ImageWall | undefined> => {
  return axios.put(`${WALLS_URL}/${wallId}`, wall).then((response) => response.data)
}

export const deleteWall = async (wallId: BCID): Promise<ImageWall | undefined> => {
  return axios.delete(`${WALLS_URL}/${wallId}`).then((response) => response.data)
}

export const createObject = async (object: ImageObjectCreate): Promise<ImageObject | undefined> => {
  return axios.post(`${OBJECTS_URL}`, object).then((response) => response.data)
}

export const updateObject = async (
  segmentId: BCID,
  segment: ImageObjectUpdate,
): Promise<ImageObject | undefined> => {
  return axios.put(`${OBJECTS_URL}/${segmentId}`, segment).then((response) => response.data)
}

export const deleteObject = async (segmentId: BCID): Promise<ImageObject | undefined> => {
  return axios.delete(`${OBJECTS_URL}/${segmentId}`).then((response) => response.data)
}

export const createEgressPath = async (
  imageId: BCID,
  point: [number, number],
): Promise<ImageLine | undefined> => {
  return axios
    .post(`${IMAGES_URL}/${imageId}/draw_egress_path`, point)
    .then((response) => response.data)
}

export const runCompliance = async (projectId: BCID): Promise<ComplianceRun | undefined> => {
  return axios
    .post(`${RUNS_URL}/compliance?project_id=${projectId}`)
    .then((response) => response.data)
}

export const drawEgress = async (imageId: BCID): Promise<undefined> => {
  return axios
    .post(`${IMAGES_URL}/${imageId}/generate_egress_paths`)
    .then((response) => response.data)
}
