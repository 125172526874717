import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useAvatarColor, useModal } from 'src/app/core/hooks'

import { AddProjectModal } from '../components/add-project-modal/AddProjectModal'
import { ProjectStatus } from '../core/_models'
import { getProjects } from '../core/_requests'
import { ProjectCard } from './ProjectCard'

const statusSort = [
  ProjectStatus.OVERDUED,
  ProjectStatus.IN_PROGRESS,
  ProjectStatus.PENDING,
  ProjectStatus.COMPLETED,
  ProjectStatus.APPROVED,
  ProjectStatus.ARCHIVED,
]

export const ProjectList = () => {
  const [projectModal, openModal, closeModal] = useModal()

  const {
    isFetching,
    data: projectList,
    refetch,
  } = useQuery('projects-list', getProjects, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const displayList = useMemo(
    () => projectList?.sort((a, b) => statusSort.indexOf(a.status) - statusSort.indexOf(b.status)),
    [projectList],
  )

  const getAvatarColor = useAvatarColor()

  return (
    <>
      {projectModal && (
        <AddProjectModal onClose={closeModal} isProjectLoading={isFetching} refetch={refetch} />
      )}
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          My Projects
          <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span>
        </h3>

        <div className='d-flex flex-wrap my-2'>
          <a href='#' className='btn btn-primary btn-sm' onClick={openModal}>
            New Project
          </a>
        </div>
      </div>
      <div className='row g-6 g-xl-9'>
        {displayList?.map(({ id, name, description, progress, status, projectUsers, avatar }) => (
          <div className='col-md-6 col-xl-6' key={id}>
            <ProjectCard
              id={id}
              badgeColor='primary'
              status={status}
              title={name}
              description={description || ' '}
              date='November 10, 2021'
              budget='$284,900.00'
              progress={progress}
              avatar={avatar}
              users={projectUsers.map(({ user: { email } }) => ({
                name: email || '',
                initials: (email || ' ')[0].toUpperCase(),
                color: getAvatarColor(email),
              }))}
            />
          </div>
        ))}
      </div>
    </>
  )
}
