import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button, // DialogContentText,
  Select,
  MenuItem,
  IconButton,
  BoxProps,
} from '@mui/material'
import { groupBy } from 'lodash'
import { uniq, map, pipe, toPairs, groupBy as groupByFP } from 'lodash/fp'
import { useCallback, useState, useEffect, FC, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { KTIcon } from 'src/_metronic/helpers'
import { useLayout } from 'src/_metronic/layout/core'
import { len } from 'src/app/core/fp'

import { useAuth } from '../auth'
import { UserBadge } from '../projects/components/UserBadge'
import { BCID } from '../projects/core/_models'
import { AddCrewModal } from './AddCrewModal'
import { CrewItemList } from './CrewItemList'
import { useCrews } from './core/_context'
import {
  CrewUserRole,
  ProjectCrewCreate,
  ProjectCrew,
  ProjectCrewCreateWithUsers,
} from './core/_models'
import { createProjectCrew, addCrewUser } from './core/_requests'

interface Props {
  sx?: BoxProps['sx']
}

export const ProjectCrews: FC<Props> = ({ sx }) => {
  const { setLayout, config } = useLayout()
  const navigate = useNavigate()

  useEffect(() => {
    setLayout(
      {
        ...config,
        header: { ...config.header, display: false },
      },
      false,
    )

    return () => {
      setLayout(
        {
          ...config,
          header: { ...config.header, display: true },
        },
        false,
      )
    }
  }, [])

  const { id } = useParams()

  const { currentUser } = useAuth()

  const {
    project,
    userList,
    crew: { list: crewList, refetch },
  } = useCrews()

  const internal = useMemo(
    () => crewList?.filter((crew) => crew.internal && !crew.private) || [],
    [crewList],
  )
  const external = useMemo(
    () => crewList?.filter((crew) => !crew.internal && !crew.private),
    [crewList],
  )
  const private_ = useMemo(
    () =>
      crewList
        ?.filter((crew) => crew.private)
        .map((dm) => ({
          ...dm,
          name:
            dm.crewUsers
              ?.filter(({ user }) => user.id !== currentUser?.id)
              .map(({ user }) => user.email)
              .join(', ') || '',
        })),
    [crewList],
  )

  const usersLeft = useMemo(
    () =>
      userList?.filter(
        (user) =>
          user.id !== currentUser?.id &&
          !crewList?.some((crew) => crew.crewUsers?.some(({ user: { id } }) => id === user.id)),
      ),
    [userList, crewList],
  )

  const grouped = useMemo(() => groupBy(external, (crew) => crew.group.split(' / ')[0]), [external])

  const [initialCrewValues, setInitialCrewValues] = useState<ProjectCrewCreateWithUsers | null>(
    null,
  )
  const handleOpenDialog = useCallback(
    (crew: ProjectCrewCreateWithUsers) => setInitialCrewValues(crew),
    [],
  )
  const handleAddCrewDialogClose = useCallback(() => {
    setInitialCrewValues(null)
    refetch()
  }, [])

  const [newDM, setNewDM] = useState<ProjectCrewCreate | null>(null)
  const [dmUser, setDMUser] = useState<BCID | ''>('')
  const handleOpenDMDialog = useCallback((crew: ProjectCrewCreate) => setNewDM(crew), [])
  const handleCloseDMDialog = useCallback(() => {
    setNewDM(null)
    setDMUser('')
  }, [])

  const handleAddDM = useCallback(async () => {
    if (!newDM || !dmUser) return
    const dmCrew = await createProjectCrew({
      ...newDM,
      name: userList.find((user) => user.id === dmUser)?.email || '',
      private: true,
    })

    await addCrewUser(dmCrew.id, { userId: dmUser, role: CrewUserRole.MEMBER })
    await addCrewUser(dmCrew.id, { userId: currentUser?.id || '', role: CrewUserRole.OWNER })

    handleCloseDMDialog()
    refetch()
  }, [newDM, dmUser])

  // TODO: rework to sockets
  useEffect(() => {
    const refreshInterval = setInterval(refetch, 5000)
    return () => clearInterval(refreshInterval)
  }, [])

  if (!id) {
    return null
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        ...sx,
      }}
    >
      <Box
        sx={{
          height: '55px',
          // backgroundColor: 'var(--bs-gray-300)',
          borderBottom: '1px solid var(--bs-gray-300)',
          boxShadow: 'var(--bs-header-fixed-box-shadow)',
          display: 'flex',
          alignItems: 'center',
          flexShrink: 0,
        }}
      >
        <Box sx={{ padding: '0 1rem' }}>
          <div className='d-flex align-items-center d-lg-none ms-n1 me-2' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTIcon iconName='abstract-14' className='fs-1' />
            </div>
          </div>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant='h5'
            sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}
          >
            {project?.name}
          </Typography>
          <Typography
            variant='h6'
            sx={{ display: 'flex', justifyContent: 'center', color: 'var(--bs-gray-400)' }}
          >
            {project?.description}
          </Typography>
        </Box>

        <IconButton sx={{ padding: '0 1rem' }} onClick={() => navigate(`/projects/${project?.id}`)}>
          <i className='bi bi-three-dots-vertical fs-1'></i>
        </IconButton>
      </Box>
      <Box sx={{ overflow: 'scroll', flexShrink: 1 }}>
        <Box>
          <Typography variant='h3' sx={{ margin: '1rem 1rem 0' }}>
            Internal:
          </Typography>
          <Box sx={{ padding: '1rem' }}>
            {internal
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((crew) => (
                <CrewItemList key={crew.id} crew={crew} />
              ))}
            <Box
              className='text-gray-600 d-block fs-4 py-1'
              onClick={() =>
                handleOpenDialog({
                  name: '',
                  internal: true,
                  projectId: id,
                })
              }
              sx={{
                padding: '0 1rem',
                cursor: 'pointer',
              }}
            >
              + Add crew
            </Box>
          </Box>
        </Box>
        <Box sx={{ borderTop: '1px solid var(--bs-text-gray-300)' }}>
          <Typography variant='h3' sx={{ margin: '1rem 1rem 0' }}>
            External:
          </Typography>
          {Object.entries(grouped).map(([key, value]) => (
            <Box key={key}>
              <Typography variant='h4' sx={{ margin: '1rem 1rem 0' }}>
                {key}
              </Typography>

              <Box sx={{ padding: '1rem' }}>
                {pipe(
                  map((crew: ProjectCrew) => crew.group),
                  uniq,
                  len,
                )(value) > 1 ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {pipe(
                      groupByFP((crew: ProjectCrew) => crew.group.split(' / ')[1]),
                      toPairs,
                      map(([subKey, value]: [string, ProjectCrew[]]) => (
                        <Box key={subKey}>
                          <Typography variant='h5' sx={{ padding: '0 1rem' }}>
                            {subKey}
                          </Typography>
                          {value
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((crew) => (
                              <CrewItemList key={crew.id} crew={crew} />
                            ))}
                          <Box
                            className='text-gray-600 d-block fs-4 py-1'
                            onClick={() =>
                              handleOpenDialog({
                                name: '',
                                group: `${key} / ${subKey}`,
                                internal: false,
                                projectId: id,
                              })
                            }
                            sx={{
                              cursor: 'pointer',
                              padding: '0 1rem',
                            }}
                          >
                            + Add crew
                          </Box>
                        </Box>
                      )),
                    )(value)}
                  </Box>
                ) : (
                  <>
                    {value
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((crew) => (
                        <CrewItemList key={crew.id} crew={crew} />
                      ))}
                    <Box
                      className='text-gray-600 d-block fs-4 py-1'
                      onClick={() =>
                        handleOpenDialog({
                          name: '',
                          group: key,
                          internal: false,
                          projectId: id,
                        })
                      }
                      sx={{
                        cursor: 'pointer',
                        padding: '0 1rem',
                      }}
                    >
                      + Add crew
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <Box>
          <Typography variant='h3' sx={{ margin: '1rem 1rem 0' }}>
            Direct Messages:
          </Typography>
          <Box sx={{ padding: '1rem' }}>
            {private_
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((crew) => (
                <CrewItemList
                  key={crew.id}
                  crew={crew}
                  icon={<UserBadge user={{ email: crew.name }} size={20} />}
                />
              ))}
            {usersLeft.length > 0 && (
              <Box
                className='text-gray-600 d-block fs-4 py-1'
                onClick={() =>
                  handleOpenDMDialog({
                    name: '',
                    projectId: id,
                  })
                }
                sx={{
                  cursor: 'pointer',
                  padding: '0 1rem',
                }}
              >
                + Add new
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <AddCrewModal open={initialCrewValues} onClose={handleAddCrewDialogClose} />
      <Dialog open={!!newDM} onClose={handleCloseDMDialog} fullWidth>
        <DialogTitle>Add new conversation</DialogTitle>
        <DialogContent>
          <Select
            value={dmUser}
            fullWidth
            onChange={(e) => setDMUser(e.target.value)}
            variant='standard'
          >
            {usersLeft?.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.email}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDMDialog}>Cancel</Button>
          <Button onClick={handleAddDM}>Add</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
