import axios, { AxiosResponse } from 'axios'
import { saveAs } from 'file-saver'

import { downloadFile, getDownloadLink } from '../../projects/core/_requests'
import { Report, Segment, SegmentUpdate, User, UserCreate, UserUpdate } from './_models'

const API_URL = process.env.REACT_APP_API_URL
export const REPORTS_URL = `${API_URL}/v1/reports`
export const REPORT_STATUS_URL = `${API_URL}/v1/reports/{report_id}/state`
export const SEGMENTS_URL = `${API_URL}/v1/documents/{document_id}/segments`
export const USERS_URL = `${API_URL}/v1/users`

export const getReports = async (): Promise<Report[] | undefined> => {
  return axios.get(REPORTS_URL).then((response: AxiosResponse) => response.data)
}

export const getSegments = async (documentId: string): Promise<Segment[] | undefined> => {
  return axios
    .get(SEGMENTS_URL.replace('{document_id}', documentId))
    .then((response: AxiosResponse) => response.data)
}

export const changeReportStatus = async (
  reportId: number,
  currentStatus: string,
  targetStatus: string,
  variables: object | undefined,
): Promise<void> => {
  return axios
    .post(REPORT_STATUS_URL.replace('{report_id}', reportId.toString()), {
      currentStatus,
      targetStatus,
      variables,
    })
    .then((response: AxiosResponse) => response.data)
}
export const updateSegment = async (
  segmentId: number,
  segment: SegmentUpdate,
): Promise<Segment | undefined> => {
  return axios
    .put(`${SEGMENTS_URL}/${segmentId}`, segment)
    .then((response: AxiosResponse) => response.data)
}

export const getReportName = (type: string): string => {
  const currentDate = new Date()
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const formattedDate = `${
    monthNames[currentDate.getMonth()]
  } ${currentDate.getDate()} ${currentDate.getFullYear()}`
  return `${type} ${formattedDate}.xlsx`
}

export const downloadReportFile = async (uuid: string, filename = 'report.xlsx') => {
  try {
    // Get the file from the S3 bucket
    const link = await getDownloadLink(uuid)
    if (link === undefined) return

    const response = await downloadFile(link)

    // Check if the Body is not undefined before creating the Blob
    if (response.data) {
      // Create a blob from the file data
      const blob = new Blob([response.data as ArrayBuffer], {
        type: 'application/octet-stream',
      })

      // Download the file using file-saver
      saveAs(blob, filename)
    } else {
      console.error('Error downloading file from S3: Empty response body')
    }
  } catch (error) {
    console.error('Error downloading file from S3:', error)
  }
}

export const getUsers = async (): Promise<User[] | undefined> => {
  return axios.get(`${USERS_URL}/admin`).then((response) => response.data)
}

export const createUser = async (user: UserCreate): Promise<User | undefined> => {
  return axios
    .post(`${USERS_URL}`, user)
    .then((response) => response.data)
    .then((response) => response.data)
}

export const updateUser = async (id: User['id'], user: UserUpdate): Promise<User | undefined> => {
  return axios
    .put(`${USERS_URL}/${id}`, user)
    .then((response) => response.data)
    .then((response) => response.data)
}

export const deleteUser = async (id: User['id']): Promise<User | undefined> => {
  return axios
    .delete(`${USERS_URL}/${id}`)
    .then((response) => response.data)
    .then((response) => response.data)
}
