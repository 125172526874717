import clsx from 'clsx'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { UsersListLoading } from 'src/app/modules/apps/user-management/users-list/components/loading/UsersListLoading'

import { createProject } from '../../core/_requests'

type Props = {
  isProjectLoading?: boolean
  onClose?: () => void
  refetch?: () => void
}

const editUserSchema = Yup.object().shape({
  description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Address is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

export const AddProjectModalForm: FC<Props> = ({ isProjectLoading = false, onClose, refetch }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const formik = useFormik({
    initialValues: {
      name: '',
      description: undefined,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const project = await createProject(values)
        enqueueSnackbar(`Project "${project?.name}" created`, {
          variant: 'success',
          action: (snackbarId) => (
            <div
              className='nav-link nav-link-border-solid btn btn-outline py-2 px-4 me-4'
              onClick={() => closeSnackbar(snackbarId)}
            >
              <Link className='nav-link text-active-primary' to={`/projects/${project?.id}/team`}>
                Open
              </Link>
            </div>
          ),
        })
      } catch (ex) {
        enqueueSnackbar(`Project "${values.name}" creation failed`, { variant: 'error' })
        console.error(ex)
      } finally {
        setSubmitting(true)
        onClose?.()
        refetch?.()
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Project Name</label>
            <input
              placeholder='Project name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                },
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isProjectLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2 required'>Address</label>
            <input
              placeholder='Address'
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.description && formik.errors.description },
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                },
              )}
              type='text'
              name='description'
              autoComplete='off'
              disabled={formik.isSubmitting || isProjectLoading}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={onClose}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isProjectLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isProjectLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Create</span>
            {(formik.isSubmitting || isProjectLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isProjectLoading) && <UsersListLoading />}
    </>
  )
}
