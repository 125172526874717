import axios from 'axios'

import { BCID, UploadFileLinkResponse } from 'src/app/modules/projects/core/_models'

import {
  ProjectCrew,
  ProjectCrewCreate,
  CrewMessage,
  CrewUserCreate,
  CrewUser,
  CrewMessageCreate,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const PROJECTS_URL = `${API_URL}/v1/projects`
export const CREWS_URL = `${API_URL}/v1/project_crews`
export const MESSAGE_URL = `${API_URL}/v1/crew_messages`
export const FILES_URL = `${API_URL}/v1/files`

export const createProjectCrew = async (projectCrew: ProjectCrewCreate): Promise<ProjectCrew> => {
  return axios.post(CREWS_URL, projectCrew).then((response) => response.data)
}

export const getProjectCrews = async (projectId?: BCID): Promise<ProjectCrew[]> => {
  if (!projectId) return []
  return axios.get(`${PROJECTS_URL}/${projectId}/crews`).then((response) => response.data || [])
}

export const createMessage = async (crewMessage: CrewMessageCreate): Promise<CrewMessage> => {
  return axios.post(MESSAGE_URL, crewMessage)
}

export const getMessages = async (crewId?: BCID): Promise<CrewMessage[]> => {
  if (!crewId) return []
  return axios.get(`${CREWS_URL}/${crewId}/messages`).then((response) => response.data || [])
}

export const addCrewUser = async (
  projectId: BCID,
  projectUser: CrewUserCreate,
): Promise<CrewUser | undefined> => {
  return axios
    .post(`${CREWS_URL}/${projectId}/users`, projectUser)
    .then((response) => response.data)
}

export const updateCrewUser = async (
  projectId: BCID,
  projectUserId: BCID,
  projectUser: Partial<CrewUserCreate>,
): Promise<CrewUser | undefined> => {
  return axios
    .put(`${CREWS_URL}/${projectId}/users/${projectUserId}`, projectUser)
    .then((response) => response.data)
}

export const deleteCrewUser = async (
  projectId: BCID,
  projectUserId: BCID,
): Promise<CrewUser | undefined> => {
  return axios
    .delete(`${CREWS_URL}/${projectId}/users/${projectUserId}`)
    .then((response) => response.data)
}

export const getUploadLink = async (): Promise<UploadFileLinkResponse | undefined> => {
  return axios.get(`${FILES_URL}/crew_attachment_link`).then((response) => response.data)
}
