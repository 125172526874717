import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import { toAbsoluteUrl } from '../../../_metronic/helpers'
// import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { Registration } from './components/Registration'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      // }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default-dark.png')}
            className='theme-dark-show h-45px'
          />
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default.png')}
            className='theme-light-show h-45px'
          ></img>
        </a>
        {/* end::Logo */}
        <div className='fw-semibold fs-4 mb-20 mt-4 text-gray-700'>
          The “Spell Check” for Project Design
        </div>
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-semibold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            End User Agreement
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Terms of Service
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Privacy Policy
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      {/* <Route path='forgot-password' element={<ForgotPassword />} /> */}
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
