import { useEffect, useState, useMemo } from 'react'

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width)
  useEffect(() => {
    window.addEventListener('resize', () => setScreenWidth(window.screen.width))
    return () => {
      window.removeEventListener('resize', () => setScreenWidth(window.screen.width))
    }
  }, [])

  return screenWidth
}

export const useIsDesktop = () => {
  const screenWidth = useScreenWidth()
  return useMemo(() => screenWidth > 999, [screenWidth])
}
