import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button, // DialogContentText,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
} from '@mui/material'
import { useCallback, useState, FC, useMemo, useEffect } from 'react'

import { useAuth } from '../auth'
import { BCID } from '../projects/core/_models'
import { useCrews } from './core/_context'
import { ProjectCrewCreateWithUsers } from './core/_models'
import { createProjectCrew } from './core/_requests'

interface Props {
  open: ProjectCrewCreateWithUsers | null
  onClose?: () => void
}

export const AddCrewModal: FC<Props> = ({ open, onClose }) => {
  const [newCrew, setNewCrew] = useState<ProjectCrewCreateWithUsers | null>(null)

  useEffect(() => {
    setNewCrew(open)
  }, [open])

  const { currentUser } = useAuth()
  const { userList } = useCrews()

  const handleCrewNameChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback((event) => {
      const { value } = event.target

      setNewCrew(
        (newCrew) =>
          newCrew && {
            ...newCrew,
            name: value.replace(/\s/g, '-').toLocaleLowerCase(),
          },
      )
    }, [])
  const handleAddCrew = useCallback(async () => {
    if (!newCrew) return
    await createProjectCrew(newCrew)

    onClose?.()
  }, [newCrew])

  const channelUserOptions = useMemo(
    () => userList.filter((user) => user.id !== currentUser?.id),
    [userList, currentUser],
  )

  const handleChannelUsersChange = (event: SelectChangeEvent<BCID[]>) => {
    const {
      target: { value },
    } = event

    // On autofill we get a stringified value.
    const users = typeof value === 'string' ? value.split(',') : value
    setNewCrew((newCrew) => newCrew && { ...newCrew, crewUsers: users })
  }

  return (
    <Dialog open={!!open} onClose={onClose} fullWidth>
      <DialogTitle>Add Crew</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Box>
          <InputLabel>Name</InputLabel>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            placeholder='crew-name'
            fullWidth
            variant='outlined'
            value={newCrew?.name}
            sx={{
              m: 0,
            }}
            onChange={handleCrewNameChange}
            InputProps={{
              startAdornment: <InputAdornment position='start'>#</InputAdornment>,
            }}
          />
        </Box>

        <Box>
          <InputLabel>Users</InputLabel>
          <Select
            onChange={handleChannelUsersChange}
            value={newCrew?.crewUsers || []}
            variant='outlined'
            multiple
            fullWidth
          >
            {channelUserOptions?.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.email}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddCrew} disabled={!newCrew?.name}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
