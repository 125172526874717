import axios from 'axios'

import { UserModel, AuthModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/users/me`
export const LOGIN_URL = `${API_URL}/v1/auth/login`
export const REGISTER_URL = `${API_URL}/v1/auth/password_change`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const REFRESH_URL = `${API_URL}/v1/auth/refresh`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(userId: string, password: string) {
  return axios.put(REGISTER_URL, { userId, password })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function getAccessByRefreshToken(refreshToken: string) {
  return axios.post(REFRESH_URL, refreshToken)
}
