import { useEffect, FC } from 'react'

import { KTIcon } from 'src/_metronic/helpers'

import { AddProjectModalForm } from './AddProjectModalForm'

interface Props {
  onClose: () => void
  isProjectLoading?: boolean
  refetch?: () => void
}

export const AddProjectModal: FC<Props> = ({ onClose, isProjectLoading = false, refetch }) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Add Project</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={onClose}
                style={{ cursor: 'pointer' }}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <AddProjectModalForm
                onClose={onClose}
                isProjectLoading={isProjectLoading}
                refetch={refetch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}
