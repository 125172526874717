import clsx from 'clsx'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { MenuComponent } from '../assets/ts/components'
import {
  ActivityDrawer,
  DrawerMessenger,
  InviteUsers,
  ThemeModeProvider,
  UpgradePlan,
} from '../partials'
import { Content } from './components/Content'
import { Footer } from './components/Footer'
import { ScrollTop } from './components/ScrollTop'
import { AsideDefault } from './components/aside/AsideDefault'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { PageDataProvider } from './core'
import { useLayout } from './core'

const MasterLayout = () => {
  const location = useLocation()
  const { config } = useLayout()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root vw-100'>
          {/* begin::Page */}
          <div className='page d-flex flex-column flex-column-fluid' style={{ height: '100dvh' }}>
            <HeaderWrapper />

            {/* begin::Wrapper */}
            <div
              className={clsx(
                'wrapper d-flex flex-column flex-row-fluid flex-grow-1 flex-shrink-1 h-100',
                !config.header.display && 'pt-0',
              )}
              id='kt_wrapper'
            >
              <AsideDefault />

              {/* <Toolbar /> */}

              {/* begin::Content */}
              <div
                id='kt_content'
                className='content d-flex flex-column flex-column-fluid flex-grow-1 p-0 h-100'
                style={{
                  overflow: 'scroll',
                }}
              >
                <Content>
                  <Outlet />
                </Content>
              </div>
              {/* end::Content */}
              {/* Disabled footer */}
              {/* <Footer /> */}
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Page */}
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }
