import dayjs, { OpUnitType } from 'dayjs'
import { curry, isNaN, isNumber } from 'lodash'
import { pipe, split, map, join } from 'lodash/fp'

export const toFixed = curry((precision: number, value: number | string) => {
  const number = isNumber(value) ? value : parseFloat(value)

  return !isNaN(number) ? number.toFixed(precision) : undefined
})

export const len = (value: string) => value.length

export const formatCurrency = pipe(
  toFixed(2),
  split('.'),
  map((numPart) => numPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')),
  join('.'),
)

export const ago = (createdAt?: string) => {
  const units: OpUnitType[] = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
  const now = dayjs()
  for (const unit of units) {
    const diff = now.diff(dayjs(createdAt), unit)

    if (diff > 0) {
      return `${diff} ${unit} ago`
    }
  }

  return ''
}
