import { Box, IconButton, Typography } from '@mui/material'
import { orderBy } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { useLayout } from 'src/_metronic/layout/core'

import { Message } from './Message'
import { MessageEditor } from './MessageEditor'
import { useCrews } from './core/_context'
import { getMessages } from './core/_requests'

export const CrewView = () => {
  const { crewId } = useParams()
  const {
    crew: { list: crewList },
  } = useCrews()
  const navigate = useNavigate()

  const { setLayout, config } = useLayout()

  useEffect(() => {
    setLayout(
      {
        ...config,
        header: { ...config.header, display: false },
      },
      false,
    )

    return () => {
      setLayout(
        {
          ...config,
          header: { ...config.header, display: true },
        },
        false,
      )
    }
  }, [])

  const currentCrew = useMemo(
    () => crewList?.find((crew) => crew.id === crewId),
    [crewList, crewId],
  )

  const {
    isLoading,
    data: messageList,
    refetch,
  } = useQuery(`crew-${crewId}-message-list`, () => getMessages(crewId), {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  // TODO: rework to sockets
  useEffect(() => {
    const refreshInterval = setInterval(refetch, 5000)
    return () => clearInterval(refreshInterval)
  }, [])

  const noMessages = useMemo(
    () => !isLoading && messageList?.length === 0,
    [isLoading, messageList],
  )

  const displayMessages = useMemo(() => orderBy(messageList, ['createdAt'], 'desc'), [messageList])

  return (
    <Box
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}
    >
      <Box
        sx={{
          height: '55px',
          borderBottom: '1px solid var(--bs-gray-300)',
          boxShadow: 'var(--bs-header-fixed-box-shadow)',
          display: 'flex',
          alignItems: 'center',
          flexShrink: 0,
        }}
      >
        <IconButton sx={{ padding: '0 1rem' }} onClick={() => navigate('..')}>
          <i className='bi bi-chevron-left fs-1'></i>
        </IconButton>
        <Typography
          variant='h5'
          sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}
        >
          {!currentCrew?.private && '#'} {currentCrew?.name}
        </Typography>
        <IconButton sx={{ padding: '0 1rem' }} onClick={() => navigate('..')}>
          <i className='bi bi-three-dots-vertical fs-1'></i>
        </IconButton>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          overflow: 'scroll',
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        {noMessages ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <i className='bi bi-chat-left-dots' style={{ fontSize: '100px' }}></i>
            <p className='text-gray-500 fs-3'>
              There are no messages in this crew yet. Become the first to send one!
            </p>
          </Box>
        ) : (
          <>
            {displayMessages.map((message) => (
              <Message key={message.id} message={message} />
            ))}
          </>
        )}
      </Box>

      <MessageEditor refetch={refetch} />
    </Box>
  )
}
