import { Box, Typography } from '@mui/material'
import { saveAs } from 'file-saver'
import { FC, useEffect, useState, useMemo, useCallback } from 'react'

import { FileIcon } from '../projects/components/FileIcon'
import { getDownloadLink } from '../projects/core/_requests'
import { downloadFile } from '../projects/core/_requests'
import { Attachment } from './core/_models'

interface AProps {
  attachments?: Attachment[]
}

export const MessageAttachments: FC<AProps> = ({ attachments }) => {
  const [attachmentLink, setAttachmentLink] = useState<string | undefined>(undefined)

  // 1 attachment limitation for PoC
  const attachment = useMemo(() => attachments?.[0], [attachments])

  useEffect(() => {
    if (!attachment?.key) return
    console.log('attachment', attachment)
    getDownloadLink(attachment.key).then(setAttachmentLink)
  }, [attachment?.key])

  const isImg = useMemo(() => attachment?.type?.startsWith('image/'), [attachment])

  const handleDownload = useCallback(async () => {
    console.log('attachmentLink', attachmentLink)
    if (!attachmentLink) return
    const response = await downloadFile(attachmentLink)

    if (response.data) {
      const blob = new Blob([response.data as ArrayBuffer], {
        type: 'application/octet-stream',
      })

      saveAs(blob, attachment?.name)
    }
  }, [attachmentLink])

  if (!attachmentLink) return null

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      onClick={handleDownload}
    >
      {isImg ? (
        <img src={attachmentLink} alt='image' style={{ width: '90%' }} />
      ) : (
        <FileIcon type={attachment?.type} size={100} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
          {attachment?.name}
        </Typography>
        <Typography variant='body2'>
          {((attachment?.size || 0) / 1024 / 1024).toFixed(1)} MB
        </Typography>
      </Box>
    </Box>
  )
}
