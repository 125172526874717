import { User } from 'src/app/modules/admin/core/_models'
import { BCID } from 'src/app/modules/projects/core/_models'

export interface ProjectCrewCreate {
  name?: string
  projectId: BCID
  internal?: boolean
  group?: string
  private?: boolean
}

export interface ProjectCrewCreateWithUsers extends ProjectCrewCreate {
  crewUsers?: BCID[]
}

export interface ProjectCrew extends ProjectCrewCreate {
  id: BCID
  internal: boolean
  group: string
  createAt: string
  createdBy: BCID
  name: string
  private: boolean
  crewUsers?: {
    role: CrewUserRole
    user: User
  }[]
}

export interface Attachment {
  key: string
  name: string
  type: string
  size: number
}

export interface CrewMessageCreate {
  message?: string
  crewId: BCID
  meta?: {
    attachments: Attachment[]
  }
}

export interface CrewMessage extends CrewMessageCreate {
  id: BCID
  crewId: BCID
  createdAt: string
  createdBy: BCID
}

export enum CrewUserRole {
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
}

export interface CrewUserCreate {
  userId: BCID
  role: CrewUserRole
}

export interface CrewUser extends CrewUserCreate {
  id: BCID
  crewId: BCID
  createdAt: string
  createdBy: BCID
}
