import clsx from 'clsx'
import { FC } from 'react'

import { useAvatarColor } from 'src/app/core/hooks'

interface Props {
  user?: {
    email?: string
  }
  className?: string
  size?: number
}

export const UserBadge: FC<Props> = ({ user: { email } = { email: '' }, className, size = 35 }) => {
  const getAvatarColor = useAvatarColor()
  return (
    <div className={clsx(`symbol symbol-${size}px symbol-circle`, className)}>
      <span className={`symbol-label bg-${getAvatarColor(email)} text-inverse-primary fw-bolder`}>
        {(email || ' ')[0].toUpperCase()}
      </span>
    </div>
  )
}
