import { SnackbarProvider } from 'notistack'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { ConfirmationDialogProvider, FTProvider } from './core/context'
import { AuthInit } from './modules/auth'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ConfirmationDialogProvider>
        <I18nProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{
              containerRoot: 'mt-12',
            }}
          >
            <LayoutProvider>
              <AuthInit>
                {/* NOTE: FTProvider should be inside AuthInit */}
                <FTProvider>
                  <Outlet />
                </FTProvider>
                {/* NOTE: MasterInit is not expecting to be rerendered */}
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </SnackbarProvider>
        </I18nProvider>
      </ConfirmationDialogProvider>
    </Suspense>
  )
}

export { App }
