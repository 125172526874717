import clsx from 'clsx'
import { FC } from 'react'

import { toAbsoluteUrl } from 'src/_metronic/helpers'

interface Props {
  avatar?: string | null
}
export const ProjectAvatar: FC<Props> = ({ avatar }) => {
  return (
    <div
      className={clsx(
        'w-100 ratio-1x1 rounded position-relative',
        !avatar && 'border border-3 p-5',
      )}
    >
      {avatar ? (
        <img
          className='w-100 ratio-1x1 rounded'
          src={toAbsoluteUrl(avatar)}
          style={{
            aspectRatio: '1/1',
          }}
        />
      ) : (
        <img
          className='w-100 ratio-1x1 rounded'
          src={toAbsoluteUrl('/media/projects/default.png')}
          style={{
            aspectRatio: '1/1',
            filter:
              'invert(70%) sepia(100%) saturate(0%) hue-rotate(197deg) brightness(95%) contrast(97%)',
          }}
        />
      )}
    </div>
  )
}
