// Axios
import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { H } from 'highlight.run'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'

/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import { AuthProvider, setupAxios } from './app/modules/auth'
import { AppRoutes } from './app/routing/AppRoutes'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

if (process.env.REACT_APP_H_ORG_ID) {
  H.init(process.env.REACT_APP_H_ORG_ID, {
    tracingOrigins: true,
    enableCanvasRecording: true, // enable canvas recording
    samplingStrategy: {
      canvas: 5, // snapshot at 2 fps
      canvasMaxSnapshotDimension: 1080, // snapshot at a max 480p resolution
      canvasInitialSnapshotDelay: 2000, // wait 1 second before taking the first snapshot
    },
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        'https://www.googleapis.com/identitytoolkit',
        'https://securetoken.googleapis.com',
      ],
    },
  })
}

if (process.env.REACT_APP_FS_ORG_ID) {
  FullStory.init({ orgId: process.env.REACT_APP_FS_ORG_ID })
}

if (process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: 'https://c6551098d4104905a9a777b4026d667b@o4505115287486464.ingest.sentry.io/4505173249884160',
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
  )
}
