import { FC, useCallback } from 'react'

import { ago } from 'src/app/core/fp'

import { useAuth } from '../auth'
import { UserBadge } from '../projects/components/UserBadge'
import { BCID } from '../projects/core/_models'
import { MessageAttachments } from './MessageAttachments'
import { useCrews } from './core/_context'
import { CrewMessage } from './core/_models'

interface Props {
  message: CrewMessage
}

export const Message: FC<Props> = ({ message }) => {
  const { currentUser } = useAuth()
  const { userList } = useCrews()

  const getUser = useCallback(
    (userId: BCID) => {
      return userList?.find((user) => user.id === userId)
    },
    [userList],
  )

  return currentUser?.id === message.createdBy ? (
    <div key={message.id} className='d-flex d-flex justify-content-end mb-10 mb-10'>
      <div className='d-flex flex-column align-items align-items-end'>
        <div className='d-flex align-items-center mb-2'>
          <div className='me-3'>
            <span className='text-muted fs-7 mb-1'>{ago(message.createdAt)}</span>
            <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'>
              You
            </a>
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <UserBadge user={currentUser} />
          </div>
        </div>
        <div
          className='p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end'
          data-kt-element='message-text'
        >
          <MessageAttachments attachments={message.meta?.attachments} />
          {message.message}
        </div>
      </div>
    </div>
  ) : (
    <div key={message.id} className='d-flex d-flex justify-content-start mb-10 mb-10'>
      <div className='d-flex flex-column align-items align-items-start'>
        <div className='d-flex align-items-center mb-2'>
          <div className='symbol  symbol-35px symbol-circle '>
            <UserBadge user={getUser(message.createdBy)} />
          </div>
          <div className='ms-3'>
            <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
              {getUser(message.createdBy)?.email}
            </a>
            <span className='text-muted fs-7 mb-1'>{ago(message.createdAt)}</span>
          </div>
        </div>
        <div
          className='p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'
          data-kt-element='message-text'
        >
          <MessageAttachments attachments={message.meta?.attachments} />
          {message.message}
        </div>
      </div>
    </div>
  )
}
