import { Box } from '@mui/material'
import { FC } from 'react'
import { Link, useParams } from 'react-router-dom'

import { ProjectCrew } from './core/_models'

interface Props {
  crew: ProjectCrew
  icon?: JSX.Element
}

export const CrewItemList: FC<Props> = ({ crew, icon }) => {
  const { id, crewId } = useParams()
  return (
    <Box
      key={crew.name}
      sx={{
        padding: '0 1rem',
        borderRadius: '0.5rem',
        backgroundColor: crewId === crew.id ? 'var(--bs-gray-300)' : 'transparent',
      }}
    >
      <Link
        key={crew.name}
        className='text-gray-500 d-block fs-4 py-1'
        to={`/project_crews/${id}/${crew.id}`}
      >
        {icon ? icon : '#'} {crew.name}
      </Link>
    </Box>
  )
}
