import { Box } from '@mui/material'
import { FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from 'src/_metronic/assets/ts/_utils'
import { WithChildren } from 'src/_metronic/helpers'
import { CrewView } from 'src/app/modules/crews/CrewView'
import { ProjectCrews } from 'src/app/modules/crews/ProjectCrews'
import { CrewsContextProvider } from 'src/app/modules/crews/core/_context'

import { useIsDesktop } from './core/_hooks'

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export const MainView = () => {
  const isDesktop = useIsDesktop()
  // const { id, crewId } = useParams()
  // const navigate = useNavigate()
  // const { crew } = useCrews()

  // useEffect(() => {
  //   if (crewId || !id || !isDesktop || !crew?.list?.length) {
  //     return
  //   }

  //   const orderedList = crew.list.sort((a, b) => (a.name > b.name ? 1 : -1))

  //   navigate(`/project_crews/${id}/${orderedList[0].id}`)
  // }, [id, crewId, isDesktop, crew])

  return (
    <Routes>
      <Route
        path=''
        element={
          <SuspensedView>
            <ProjectCrews />
          </SuspensedView>
        }
      />
      <Route
        path=':crewId'
        element={
          <SuspensedView>
            {isDesktop ? (
              <Box sx={{ display: 'flex', height: '100%' }}>
                <ProjectCrews
                  sx={{
                    flexShrink: 0,
                    backgroundColor: 'var(--bs-gray-100)',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    borderRight: '1px solid var(--bs-gray-300)',
                    zIndex: 1,
                  }}
                />
                <Box sx={{ flexGrow: 1, flexShrink: 1 }}>
                  <CrewView />
                </Box>
              </Box>
            ) : (
              <CrewView />
            )}
          </SuspensedView>
        }
      />
    </Routes>
  )
}

export const MainViewWrapper = () => (
  <CrewsContextProvider>
    <MainView />
  </CrewsContextProvider>
)
