import { createContext, useContext, useMemo } from 'react'
import { FC } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { WithChildren } from 'src/_metronic/helpers'
import { User } from 'src/app/modules/admin/core/_models'
import { Project } from 'src/app/modules/projects/core/_models'
import { getProjectById } from 'src/app/modules/projects/core/_requests'

import { ProjectCrew } from './_models'
import { getProjectCrews } from './_requests'

interface CrewsContextState {
  userList: User[]
  project?: Project
  crew: {
    list: ProjectCrew[]
    refetch: () => void
  }
}

const initialValues = {
  userList: [],
  project: undefined,
  crew: {
    list: [],
    refetch: () => undefined,
  },
}

export const CrewsContext = createContext<CrewsContextState>(initialValues)
export const useCrews = () => useContext(CrewsContext)

export const CrewsContextProvider: FC<WithChildren> = ({ children }) => {
  const { id } = useParams<{ id: string }>()
  const { data: project } = useQuery(`project-detail-${id}`, () => getProjectById(id || ''), {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: (_, { message: status }) => status === '401',
  })

  // const { data: userList } = useQuery(`get-user-list`, getUsers, {
  //   cacheTime: 0,
  //   keepPreviousData: true,
  //   refetchOnWindowFocus: false,
  //   retry: (_, { message: status }) => status === '401',
  // })

  const userList = useMemo(
    () => project?.projectUsers.map((user) => user.user) || [],
    [project?.projectUsers],
  )

  const { data: crewList, refetch: refetchCrews } = useQuery(
    `project-${id}-crew-list`,
    () => getProjectCrews(id),
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )

  return (
    <CrewsContext.Provider
      value={{
        userList: userList || [],
        project,
        crew: {
          list: crewList || [],
          refetch: refetchCrews,
        },
      }}
    >
      {children}
    </CrewsContext.Provider>
  )
}
