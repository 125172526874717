import { FC, useMemo } from 'react'

import { ProjectStatus } from '../core/_models'

export const statusMap = {
  [ProjectStatus.APPROVED]: { label: 'Approved', color: 'badge-light-info' },
  [ProjectStatus.ARCHIVED]: { label: 'Archived', color: 'badge-light' },
  [ProjectStatus.COMPLETED]: { label: 'Completed', color: 'badge-light-success' },
  [ProjectStatus.IN_PROGRESS]: { label: 'In progress', color: 'badge-light-primary' },
  [ProjectStatus.OVERDUED]: { label: 'Overdue', color: 'badge-light-danger' },
  [ProjectStatus.PENDING]: { label: 'New', color: 'badge-light' },
}

export const StatusBadge: FC<{ status: ProjectStatus }> = ({ status }) => {
  const { label, color } = useMemo(() => statusMap[status], [status])
  return (
    <div className='card-toolbar mt-0'>
      <span className={`badge ${color} fw-bolder me-auto px-4 py-3`}>{label}</span>
    </div>
  )
}
