import { pipe, sum, map } from 'lodash/fp'
import { ChangeEvent, useCallback, useState } from 'react'

export * from './modal'
export * from './debounce'

const avatarColorList = ['primary', 'success', 'info', 'danger']
const chooseColor = pipe(
  map((char: string) => char.charCodeAt(0)),
  sum,
  (val) => avatarColorList[val % avatarColorList.length],
)

export const useAvatarColor = () => {
  return chooseColor
}

export const useSearch = () => {
  const [searchValue, setSearchValue] = useState<string>('')

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    [],
  )

  return {
    searchValue,
    handleSearchChange,
  }
}
