import { AxiosStatic } from 'axios'

import { AuthModel } from './_models'
import { getAccessByRefreshToken } from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

// eslint-disable-next-line
export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'

  // Bearer Token
  axios.interceptors.request.use(
    (config: { headers: { auth: string } }) => {
      const auth = getAuth()

      if (auth && auth.accessToken) {
        config.headers.auth = `Bearer ${auth.accessToken}`
      }

      return config
    },
    (err: unknown) => Promise.reject(err),
  )

  // refresh lock
  let isRefreshing = false

  // refresh token retries
  axios.interceptors.response.use(
    (res: unknown) => res,
    async (err: unknown) => {
      const axios_ = axios as AxiosStatic
      if (!axios_.isAxiosError(err) || err.response?.status !== 401 || isRefreshing) {
        // propagate an error in other cases
        throw err
      }
      isRefreshing = true
      try {
        const auth = getAuth()
        const newAuth = await getAccessByRefreshToken(auth?.refreshToken || '')
        setAuth(newAuth.data)

        // retry just this one so after F5 it will no redirect to /login
        // other requests will be resend by react-query
        return await axios_(err.config)
      } catch {
        // reload in case of refresh token error
        // will redirect user to /auth page
        window.location.reload()
      } finally {
        // release lock
        isRefreshing = false
      }
    },
  )
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
