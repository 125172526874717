import { WithChildren } from '_metronic/helpers'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useState, ReactNode } from 'react'

import { useAuth } from 'src/app/modules/auth'

const clientSideID = process.env.REACT_APP_LD_CLIENT_ID || '6478e738b8406612e9a52e86'

interface Context {
  Provider?: ({ children }: { children: ReactNode }) => JSX.Element
}

/**
 * Feature toggles provider
 *
 * Usage example
 *
 * export const Component: FC () => {
 *   const { test1 } = useFlags()
 *   ...
 * }
 *
 * Make sure you flag is exposed to client side SDKs
 */
export const FTProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth()
  const [context, setContext] = useState<Context>({ Provider: undefined })

  useEffect(() => {
    if (!currentUser) {
      return
    }

    asyncWithLDProvider({
      clientSideID,
      context: {
        key: currentUser.id,
        email: currentUser.email,
        kind: 'user',
        id: currentUser.id,
        created_at: currentUser.createdAt,
        is_admin: currentUser.isAdmin,
      },
      options: {
        streaming: false,
      },
    }).then((Provider) => setContext({ Provider }))
  }, [currentUser])

  return context.Provider ? <context.Provider>{children}</context.Provider> : <div>{children}</div>
}
