import { Box, Button, IconButton, TextField } from '@mui/material'
import { useState, useCallback, FC, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { uploadFile } from 'src/app/modules/projects/core/_requests'

import { CrewMessageCreate } from './core/_models'
import { createMessage, getUploadLink } from './core/_requests'

interface Props {
  refetch?: () => void
  beforeSend?: (message: CrewMessageCreate) => void
}

export const MessageEditor: FC<Props> = ({ refetch, beforeSend }) => {
  const { crewId } = useParams()
  const [message, setMessage] = useState('')
  // const [attachements, setAttachemnts] = useState<FileList | null>(null)
  // const [files, setFiles] = useState<FileList | null>(null)

  const handleSend = useCallback(async () => {
    if (!crewId) return

    const messageToCreate = {
      message,
      crewId,
    }

    beforeSend?.(messageToCreate)
    await createMessage(messageToCreate)
    setMessage('')
    refetch?.()
  }, [message, crewId])

  const inputFileRef = useRef<HTMLInputElement>(null)

  const handleAttach: React.ChangeEventHandler<HTMLInputElement> = useCallback(async (e) => {
    if (!crewId) return

    const files = e.target.files
    if (!files) return

    const file = files[0]

    if (!file) return

    const uploadLink = await getUploadLink()

    e.target.files = null
    e.target.value = ''

    if (!uploadLink) return

    const messageToCreate = {
      crewId,
      meta: {
        attachments: [
          {
            key: uploadLink.key,
            name: file.name,
            type: file.type,
            size: file.size,
          },
        ],
      },
    }

    beforeSend?.(messageToCreate)

    await uploadFile(uploadLink.link, file)

    await createMessage(messageToCreate)

    refetch?.()
  }, [])

  return (
    <Box
      sx={{
        position: 'relative',
        borderTop: '1px solid var(--bs-gray-300)',
        display: 'flex',
      }}
    >
      <TextField
        sx={{
          height: '100%',
          width: '100%',
          [`& fieldset`]: {
            // borderRadius: 0,
            border: 'none',
          },
          '& .MuiInputBase-root': {
            minHeight: '100px',
          },
        }}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        multiline
        InputProps={{
          endAdornment: (
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              {message ? (
                <Button type='submit' onClick={handleSend}>
                  Send
                </Button>
              ) : (
                <IconButton onClick={() => inputFileRef.current?.click()}>
                  <i className='bi bi-paperclip fs-1'></i>
                  <input type='file' hidden ref={inputFileRef} onChange={handleAttach} />
                </IconButton>
              )}
            </Box>
          ),
        }}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter' && !ev.shiftKey) {
            handleSend()
            ev.preventDefault()
          }
        }}
      />
    </Box>
  )
}
