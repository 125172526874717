/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'

import { useAuth } from 'src/app/modules/auth'
import { getProjects } from 'src/app/modules/projects/core/_requests'

import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  const { currentUser } = useAuth()

  const {
    isFetching,
    data: projectList,
    refetch,
  } = useQuery('projects-list', getProjects, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  return (
    <>
      {projectList?.map((project) => (
        <AsideMenuItem
          key={project.id}
          to={`/project_crews/${project.id}`}
          title={project.name}
          fontIcon='bi-house fs-2'
          bsTitle={project.name}
          className='py-2'
        />
      ))}
      <AsideMenuItem
        to='/projects'
        title='Project setup'
        fontIcon='bi bi-gear fs-2'
        bsTitle={intl.formatMessage({ id: 'MENU.PROJECTS' })}
        className='py-2'
      />
      {currentUser?.isAdmin && (
        <AsideMenuItem
          to='/apps/user-management/users'
          title='Admin'
          bsTitle='Users Management'
          fontIcon='bi bi-gear fs-2'
        />
      )}
    </>
  )
}
