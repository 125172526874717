import { FC } from 'react'
import { Link } from 'react-router-dom'

import { toAbsoluteUrl } from '../../../helpers'

const Header: FC = () => {
  return (
    <div
      id='kt_header_menu'
      className='header-menu align-items-stretch'
      data-kt-drawer='true'
      data-kt-drawer-name='header-menu'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      {/* begin::Brand */}
      <div className='aside-logo  py-8' id='kt_aside_logo'>
        <Link to='/' className='mx-0 d-flex align-items-center me-lg-10'>
          <img src={toAbsoluteUrl('/media/logos/default.png')} alt='logo' className='h-20px logo' />
        </Link>
      </div>
      {/* end::Brand */}
      {/*<div*/}
      {/*  className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'*/}
      {/*  id='#kt_header_menu'*/}
      {/*  data-kt-menu='true'*/}
      {/*>*/}
      {/*  <MenuInner />*/}
      {/*</div>*/}
    </div>
  )
}

export { Header }
