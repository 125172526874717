import clsx from 'clsx'
import { get } from 'lodash'
import { FC } from 'react'

import { toAbsoluteUrl } from 'src/_metronic/helpers'

interface Props {
  type?: string
  size?: number
  className?: string
}

const iconMap = {
  'application/pdf': '/media/svg/files/pdf.svg',
  'image/png': '/media/svg/files/png.png',
  'image/jpeg': '/media/svg/files/jpg.png',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '/media/svg/files/xls.png',
  'text/csv': '/media/svg/files/csv-file.png',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    '/media/svg/files/doc.png',
  'application/zip': '/media/svg/files/zip.png',
}

export const FileIcon: FC<Props> = ({ type = '', size = 35, className }) => {
  const iconPath = get(iconMap, type, '/media/svg/files/blank-image.svg')
  return (
    <div className={clsx(`symbol symbol-${size}px`, className)}>
      <img src={toAbsoluteUrl(iconPath)} alt='icon' />
    </div>
  )
}
