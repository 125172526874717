import { useCallback, useState } from 'react'

export const useModal = (): [boolean, () => void, () => void] => {
  const [modal, setModal] = useState(false)

  const openModal = useCallback(() => setModal(true), [])
  const closeModal = useCallback(() => setModal(false), [])

  return [modal, openModal, closeModal]
}

export const useModalWithValue = <T = boolean>(): [
  T | undefined,
  (param: T) => void,
  () => void,
] => {
  const [modal, setModal] = useState<T>()

  const openModal = useCallback((param?: T) => setModal(param), [])
  const closeModal = useCallback(() => setModal(undefined), [])

  return [modal, openModal, closeModal]
}
